<template>
  <v-dialog v-model="dialog" width="800">
    <v-card>
      <v-card-title>Päivitä {{ tenant.name }} tiedot laskutusjärjestelmään</v-card-title>

      <!-- CHANGEABLE FIELDS -->
      <v-card-text>
        <h4 class="mb-1">
          Haluatko päivittää seuraavat muuttuneet tiedot
          {{ currentInvoiceService }}-järjestelmään?
        </h4>

        <div v-for="(field, idx) in Object.values(flattenedTrackedFields)" :key="'a' + idx">
          <div v-if="field.old != field.current">
            <p>
              {{ translateName(field.path) }}:
              <span class="success--text">{{
                typeof field.current === "boolean" ? formatBoolean(field.current) : field.current
              }}</span>
            </p>
          </div>
        </div>
      </v-card-text>

      <v-card-text>
        <v-checkbox
          style="margin-top: 0px"
          v-model="updateDataToService"
          label="Päivitä muuttuneet tiedot laskutusjärjestelmään"
        ></v-checkbox>

        <p>
          HUOM! Päivittyneet tiedot eivät suoraan siirry aktiivisille toistuvais- tai
          luonnoslaskuille. Muista käydä muokkaamassa myös laskua ja tallentaa se muuttuneilla
          tiedoilla.
        </p>

        <p v-if="isTalenomService">
          Talenomissa sähköisen laskun tiedot, laskun kieli sekä asiakkaan tyyppi ja Y-tunnus
          tulevat aina Talenomin kontaktin takaa. Niitä ei voi muuttaa suoraan Vuokranetin
          laskupohjalta ja siksi ne on tärkeä tallentaa Talenom-järjestelmään käyttämällä "Päivitä
          muuttuneet tiedot laskutusjärjestelmään"-ehtoa ja tallentaa.
        </p>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" outlined @click="submit">Tallenna</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "@/mixins/mixins";
import invoiceMixins from "@/mixins/invoiceMixins";
import { mapState } from "vuex";

export default {
  mixins: [mixins, invoiceMixins],

  props: {
    value: { type: Boolean, default: false },
    tenant: {
      type: Object,
      default: () => {
        return { name: "vuokralaisen" };
      },
    },
  },

  data() {
    return {
      updateDataToService: true,
    };
  },

  computed: {
    ...mapState("tenant", ["currentTenant", "trackedFields"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    flattenedTrackedFields() {
      const flattened = this.flattenObject(this.trackedFields);
      return Object.keys(flattened).reduce((result, path) => {
        const baseKey = path.split(".").slice(0, -1).join(".");
        const key = path.split(".").pop();
        if (!result[baseKey]) result[baseKey] = { path: baseKey };
        result[baseKey][key] = flattened[path];
        return result;
      }, {});
    },
  },

  methods: {
    submit() {
      this.$emit("update", this.updateDataToService);
    },

    translateName(fieldName) {
      switch (fieldName) {
        case "name":
          return "Nimi";
        case "phone":
          return "Puhelin";
        case "email":
          return "Yhteystiedon ja laskutuksen email";
        case "evoice.evoiceAddress":
          return "Verkkolaskuosoite";
        case "evoice.evoiceId":
          return "Operaattoritunnus";
        case "evoice.evoiceOperator":
          return "Verkkolaskun välittäjä";
        case "invoiceLanguage":
          return "Laskun kieli";
        case "isCompany":
          return "Yritys";
        case "businessId":
          return "Y-tunnus";
      }
    },
  },
};
</script>

<style></style>
